<template>
  <modal-card :title="'Product'">
    <product-details
      ref="product"
      :product-id="productId"
      is-add
      @processing="processing = $event"
      @valid="isValid = $event"
      @loading="loading = $event"
      @hasChange="hasChange = $event"
      @success="onSuccess"
    >
      <div slot="footer-details" />
    </product-details>

    <button
      slot="footer"
      :class="{ 'is-loading': processing }"
      :disabled="!isValid || loading || processing || !hasChange"
      type="submit"
      class="button is-success"
      @click="save"
    >
      Save
    </button>
  </modal-card>
</template>

<script>
export default {
  name: "ProductModal",
  components: {
    "product-details": () => import("@shared/product/_productDetails")
  },
  props: {
    productId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      isValid: false,
      hasChange: true
    };
  },
  methods: {
    save() {
      this.$refs.product.saveProduct();
    },
    onSuccess() {
      this.$router.push({ path: `/admin/settings/products/${this.productId}` });
      this.$emit("close");
    }
  }
};
</script>
